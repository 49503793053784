.game-bag-container {
    width: 100%;
    min-height: 101vh;
    background-color: #100E44;
    box-sizing: border-box;
    margin: 0px auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    /* overflow: hidden; */
    overflow: visible;
    /* border-radius: 2%; */
}

.btn-game {
    box-sizing: border-box;
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    background-color: transparent;
    border: 2px solid #e74c3c;
    border-radius: 0.6em;
    color: #e74c3c;
    cursor: pointer;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-self: center;
        -ms-flex-item-align: center;
            align-self: center;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1;
    margin: 20px;
    padding: 1.2em 2.8em;
    text-decoration: none;
    text-align: center;
    text-transform: uppercase;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
  }

.btn-game:hover {
  color: white;
  outline: 0;
}
.navigate-game {
  border-color: #F7B857;
  color: #F7B857;
  background-image: -webkit-linear-gradient(45deg, #F7B857 50%, transparent 50%);
  background-image: linear-gradient(45deg, #F7B857 50%, transparent 50%);
  background-position: 100%;
  background-size: 400%;
  -webkit-transition: background 300ms ease-in-out;
  transition: background 300ms ease-in-out;
}
.navigate-game:hover {
  background-position: 0;
}

.game-bag-box {
    max-width : 1024px;
    min-height: 550px;
    /* mask-image: url('../../../../public/2x/red_fragMagician.png');
    mask-position: center;
    mask-repeat: no-repeat;
    mask-size: contain; */
    background-image: url('../../../../public/2x/blue_fragMagician.png');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    /* background-color: #B6B5FF; */
    border: 2px solid #9BE1E8;
    margin: 30px auto;
    animation: fadeIn 1s;
    display: flex;
    justify-content: space-around;
    align-items: flex-end;
    flex-wrap: wrap;
}

.game-click-text {
    font-size: 5rem;
    text-transform: uppercase;
    display: inline;
    border: 4px solid #9BE1E8;
    /* position: relative; */
    /* top: -450px; */
    color: #9BE1E8;
    padding: 10px;
    margin: 35px;
    z-index: 0;
}


.game-disable {
    pointer-events: none;
}