body {
  /* font-family: 'VT323', monospace; */
  font-family: 'Courier New', Courier, monospace;
}

.container {
  width: 100%;
  height: 100%;
  /* background-color: #182c47; */
  background-color: black;
  /* background-image: linear-gradient(22deg, #001818 23%, #0E2A2C 50%);   */
}