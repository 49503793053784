:root {
    --dice-cup-container-top: 65px;
}


.game-dice-cup-container {
    width: 100px;
    /* height: 135.33px; */
    height: 270.66px;
    margin: 10px 50px;
    position: relative;
    top: var(--dice-cup-container-top);
    /* border: 10px solid black; */
}


.game-white-ball {
    width: 100%;
    height: 50%;
    background-image: url('../../../../../public/SVG/whiteball_whole.svg');
    position: relative;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 80%;
    transition: opacity 0.18s, transform 0.18s;
    animation: Game-moving-around 1.5s 0s infinite ease-in-out forwards;
}

.game-blue-ball {
    width: 100%;
    height: 50%;
    background-image: url('../../../../../public/SVG/blueBall_whole.svg');
    position: relative;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 80%;
    transition: opacity 0.18s, transform 0.18s;
    animation: Game-moving-around 1.5s 0s infinite ease-in-out forwards;
}




.game-dice-cup {
    background: url('../../../../../public/SVG/blue_bottleJar.svg') no-repeat;
    background-size: cover;
    width: 100%;
    height: 50%;
    /* top: 50%; */
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    transform-origin: center;
    transition: transform 0.18s;
}


.game-dice-cup-container:hover .game-dice-cup {
    transform: translateY(-20%);
}

.game-white-ball.game-is-active, .game-blue-ball.game-is-active {
    transform: translateY(90%);
    opacity: 0;
}



.game-dice-cup::before {
    background: url('../../../../../public/SVG/blue_leftHand.svg') no-repeat;
    background-size: cover;
    width: 143.94px;
    height: 100px;
    content: '';
    position: absolute;
    top: -150px;
    transition: top 0.2s;
    opacity: 0;
}

.game-dice-cup::after {
    background: url('../../../../../public/SVG/blue_rightHand.svg') no-repeat;
    background-size: cover;
    width: 143.94px;
    height: 100px;
    content: '';
    position: absolute;
    top: -150px;
    transition: top 0.2s;
    opacity: 0;
}

.game-dice-text {
    font-size: 5rem;
}


.game-swap-right {
    animation: Game-Up 0.2s 0s 1 ease-in-out forwards;
    transition: transform 0.2s;
    z-index: 1;
}
  
.game-swap-left {
    animation: Game-Down 0.2s 0s 1 ease-in-out forwards;
    transition: transform 0.2s;
}


.game-dice-cup-container.game-swap-right .game-dice-cup::after  {
    display: block;
    animation: Game-handDown 0.1s linear 0s 1 forwards, Game-handAppearAndDisappear 0.2s linear 0s 1;
}

.game-dice-cup-container.game-swap-left .game-dice-cup::before {
    display: block;
    animation: Game-handDown 0.1s linear 0s 1 forwards, Game-handAppearAndDisappear 0.2s linear 0s 1;
}

@keyframes Game-Down {
  0% {
    top: var(--dice-cup-container-top);
  }
  50% {
    top: calc(var(--dice-cup-container-top) + 50px);
  }
  100% {
    top: var(--dice-cup-container-top);
  }
}

@keyframes Game-Up {
  0% {
    top: var(--dice-cup-container-top);
  }
  50% {
    top: calc(var(--dice-cup-container-top) - 50px);
  }
  100% {
    top: var(--dice-cup-container-top);
  }
}

@keyframes Game-handDown {
    0% {
        top: -75px;
    }
    100% {
        top: -65px;
    }
}

@keyframes Game-handAppearAndDisappear {
    0% {
        opacity: 0;
    }

    20% {
        opacity: 1;
    }

    80% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@keyframes Game-appear {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}


@keyframes Game-moving-around {
    0% {
        top: 0;
    }
    25% {
        top: 5px;
    }

    50% {
        top: 0;
    }

    75% {
        top: -5px;
    }

    100% {
        top: 0px;
    }
}