.colorcanvas-container {
    width: 100%;
    min-height: 100vh;
    background: black;
    background-image: linear-gradient(white 2px, transparent 0), linear-gradient(90deg, white 2px, transparent 0);
    /* linear-gradient(white 1px, transparent 0),
    linear-gradient(90deg, white 1px, transparent 0); */
    background-size: 100px 100px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.colorcanvas-item {
    height: 100px;
    width: 100px;
    background-color: #767B97;
    border-radius: 50%;
}

.canvas {
    /* height: 100px;
    width: 100px; */
}