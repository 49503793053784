.rocket-star {
    background-image: url('../../../../public/stars/Celestial-items-Gold-120.png');
    background-repeat: no-repeat;
    background-size: contain;
    width: 30px;
    height:30px;
    position: absolute;
    inset: 0;
    margin: auto;
}
