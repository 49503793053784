.rocket-container {
    max-width: 1024px;
    min-height: 400vh;
    background-color: black;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
}

.rocket-wrapper-outer {
    width: 100%;
    height: 100vh;
    background-color: #FBAB7E;
    background-image: linear-gradient(62deg, #FBAB7E 0%, #F7CE68 100%);    
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    flex-direction: row;
}

.rocket-description {
    width: 40%;
    height: 100vh;
    position: fixed;
    transform: translateX(140%);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.btn {
    box-sizing: border-box;
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    background-color: transparent;
    border: 2px solid #e74c3c;
    border-radius: 0.6em;
    color: #e74c3c;
    cursor: pointer;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-self: center;
        -ms-flex-item-align: center;
            align-self: center;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1;
    margin: 20px;
    padding: 1.2em 2.8em;
    text-decoration: none;
    text-align: center;
    text-transform: uppercase;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    z-index: 100;
  }

.btn:hover {
  color: #DADCE0;
  outline: 0;
}
.navigate {
  border-color: #142D4F;
  color: #142D4F;
  background-image: -webkit-linear-gradient(45deg, #142D4F 50%, transparent 50%);
  background-image: linear-gradient(45deg, #142D4F 50%, transparent 50%);
  background-position: 100%;
  background-size: 400%;
  -webkit-transition: background 300ms ease-in-out;
  transition: background 300ms ease-in-out;
}
.navigate:hover {
  background-position: 0;
}
  
.rocket-description-box {
    width: 70%;
    margin: 30px auto;
    background-image: radial-gradient(circle at 100% 100%, transparent 0px, #142d4f 0px, #142d4f 1px, transparent 1px), linear-gradient(to right, #142d4f, #666699), radial-gradient(circle at 0% 100%, transparent 0px, #666699 0px, #666699 1px, transparent 1px), linear-gradient(to bottom, #666699, #666699), radial-gradient(circle at 0% 0%, transparent 0px, #666699 0px, #666699 1px, transparent 1px), linear-gradient(to left, #666699, #142d4f), radial-gradient(circle at 100% 0%, transparent 0px, #142d4f 0px, #142d4f 1px, transparent 1px), linear-gradient(to top, #142d4f, #142d4f);
    background-size: 1px 1px, calc(100% - 2px) 1px, 1px 1px, 1px calc(100% - 2px);
    background-position: top left,top center,top right,center right, bottom right,bottom center,bottom left,center left;
    background-repeat: no-repeat;
    /* background-color: #DADCE0; */
}

.rocket-description-heading {
    color: #142D4F;
    font-size: 50px;
    font-family: 'Merriweather', serif;
    display: block;
    text-align: center;
}

.rocket-description-words {
    color: #0a1424;
    font-size: 20px;
    font-family: 'Merriweather', serif;
    display: block;
    text-align: center;
    margin: 0 0 40px 0;
}

.rocket-canvas {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    flex-direction: column;
}

.rocket-itself {
    mask-image: url('../../../../public/SVG/rocket.svg');
    mask-repeat: no-repeat;
    mask-size: contain;
    background-color: white;
    width: 100px;
    height: 350px;
    position: relative;
    animation: floating 1s infinite;
    z-index: 6
}

.circle {
    width: 550px;
    height: 550px;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #4158D0;
    background-image: linear-gradient(43deg, #4158D0 0%, #C850C0 46%, #FFCC70 100%);
}

.inner-circle {
    position: absolute;
    content: '';
    background-color: #142D4F;
    width: 540px;
    height: 540px;
    border-radius: 50%;
    overflow: hidden;
    box-shadow: inset 0px 0px 20px 2px rgb(61, 61, 61);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

/* .rocket-itself::before {
    content: '';
    background-color: red;
    position: absolute;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
} */
@keyframes floating {
    0% {top: 0; left: -3px}
    25% {top: 3px; left: 0}
    50% {top: 0; left: 3px}
    75% {top: -3px; left: 0}
    100% {top: 0; left: -3px}
}


